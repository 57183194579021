import React, { useState } from "react";
import { removeRoommateAptIdsFromAllUsers } from "../backend/dbAPI"; // Adjust the path based on where your function is

const TestPage = () => {
  const [userId, setUserId] = useState("");
  const [apartmentId, setApartmentId] = useState("");
  const [message, setMessage] = useState("");

  const handleRemove = async () => {
    if (!userId || !apartmentId) {
      setMessage("Please enter both User ID and Apartment ID");
      return;
    }

    try {
      //Safety purposes. a user can wipe out the wholle prod user db lmao.
      //   await removeRoommateAptIdsFromAllUsers();
      console.log(userId);
      setMessage("User removed and interest decremented successfully");
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Test Remove Apartment Document</h1>
      <div>
        <label>User ID: </label>
        <input
          type="text"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          placeholder="Enter User ID"
        />
      </div>
      <div>
        <label>Apartment ID: </label>
        <input
          type="text"
          value={apartmentId}
          onChange={(e) => setApartmentId(e.target.value)}
          placeholder="Enter Apartment ID"
        />
      </div>
      <button onClick={handleRemove} style={{ marginTop: "10px" }}>
        Remove User from Apartment
      </button>
      <p>{message}</p>
    </div>
  );
};

export default TestPage;
