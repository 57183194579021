import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { ScanCommand, QueryCommand } from "@aws-sdk/lib-dynamodb";
import { unmarshall } from "@aws-sdk/util-dynamodb";
import AWS from "aws-sdk";

const AWS_REGION = process.env.REACT_APP_AWS_REGION || "us-east-2"; // Set default region
const TABLE_NAME = "ApartmentsTable-restored"; // Your DynamoDB table name

// Initialize the DynamoDB client
AWS.config.update({
  region: AWS_REGION, // Update to your region
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const dynamodb = new AWS.DynamoDB();

const parseId = (aptId) => {
  let groupId = "single_apartments"; // Default group ID if no slash is present
  let apartmentId = aptId;

  if (apartmentId.includes("/")) {
    const [groupKey, id] = apartmentId.split("/");
    groupId = groupKey;
    apartmentId = aptId;
  }

  return { groupId, apartmentId };
};

export const fetchUgroupApartments = async () => {
  const params = {
    TableName: TABLE_NAME, // Your table name
    KeyConditionExpression: "apartment_group_id = :v1",
    ExpressionAttributeValues: {
      ":v1": { S: "ugroup" },
    },
  };

  try {
    const data = await dynamodb.query(params).promise();
    const unmarshalledData = data.Items.map((item) =>
      AWS.DynamoDB.Converter.unmarshall(item)
    );

    return unmarshalledData;
  } catch (error) {
    console.error("Error querying DynamoDB:", error);
  }
};

export const fetchGSRApartments = async () => {
  const params = {
    TableName: TABLE_NAME, // Your table name
    KeyConditionExpression: "apartment_group_id = :v1",
    ExpressionAttributeValues: {
      ":v1": { S: "gsr" },
    },
  };

  try {
    const data = await dynamodb.query(params).promise();
    const unmarshalledData = data.Items.map((item) =>
      AWS.DynamoDB.Converter.unmarshall(item)
    );

    return unmarshalledData;
  } catch (error) {
    console.error("Error querying DynamoDB:", error);
  }
};

export const fetchAllGroupApartments = async () => {
  const params = {
    TableName: TABLE_NAME, // Your table name
    FilterExpression: "apartment_group_id <> :v1", // Filter to exclude 'single_apartments'
    ExpressionAttributeValues: {
      ":v1": { S: "single_apartments" },
    },
    Limit: 20, // Return only the first 20 items
  };

  try {
    const data = await dynamodb.scan(params).promise(); // Use scan instead of query for filtering
    const unmarshalledData = data.Items.map((item) =>
      AWS.DynamoDB.Converter.unmarshall(item)
    );

    return unmarshalledData;
  } catch (error) {
    console.error("Error querying DynamoDB:", error);
  }
};

export const fetchJSMApartments = async () => {
  const params = {
    TableName: TABLE_NAME, // Your table name
    KeyConditionExpression: "apartment_group_id = :v1",
    ExpressionAttributeValues: {
      ":v1": { S: "jsm" },
    },
  };

  try {
    const data = await dynamodb.query(params).promise();
    const unmarshalledData = data.Items.map((item) =>
      AWS.DynamoDB.Converter.unmarshall(item)
    );

    return unmarshalledData;
  } catch (error) {
    console.error("Error querying DynamoDB:", error);
  }
};

const fetchGroupApartment = async (group_name) => {
  const params = {
    TableName: TABLE_NAME, // Your table name
    KeyConditionExpression: "apartment_group_id = :v1",
    ExpressionAttributeValues: {
      ":v1": { S: group_name },
    },
  };

  try {
    const data = await dynamodb.query(params).promise();
    const unmarshalledData = data.Items.map((item) =>
      AWS.DynamoDB.Converter.unmarshall(item)
    );

    return unmarshalledData;
  } catch (error) {
    console.error("Error querying DynamoDB:", error);
  }
};

export const fetchMHMApartments = async () => {
  return await fetchGroupApartment("mhm");
};

export const fetchBankierApartments = async () => {
  return await fetchGroupApartment("bankier");
};

export const fetchAcademyApartments = async () => {
  return await fetchGroupApartment("academy");
};

export const fetchSingleApartments = async () => {
  const params = {
    TableName: TABLE_NAME, // Your table name
    KeyConditionExpression: "apartment_group_id = :v1",
    ExpressionAttributeValues: {
      ":v1": { S: "single_apartments" },
    },
  };

  try {
    const data = await dynamodb.query(params).promise();
    const unmarshalledData = data.Items.map((item) =>
      AWS.DynamoDB.Converter.unmarshall(item)
    );

    return unmarshalledData;
  } catch (error) {
    console.error("Error querying DynamoDB:", error);
  }
};

export const fetchSpecificApartment = async (groupId, id) => {
  const params = {
    TableName: TABLE_NAME, // Your table name
    KeyConditionExpression: "apartment_group_id = :v1 AND id = :v2", // Match both apartment_group_id and id
    ExpressionAttributeValues: {
      ":v1": { S: groupId },
      ":v2": { S: id },
    },
  };

  try {
    const data = await dynamodb.query(params).promise();
    const unmarshalledData = data.Items.map((item) =>
      AWS.DynamoDB.Converter.unmarshall(item)
    )[0];
    console.log("specifc", unmarshalledData);
    return unmarshalledData;
  } catch (error) {
    console.error("Error querying DynamoDB:", error);
  }
};

export const fetchLeanSpecificApartment = async (groupId, id) => {
  const params = {
    TableName: TABLE_NAME, // Your table name
    KeyConditionExpression: "apartment_group_id = :v1 AND id = :v2", // Match both apartment_group_id and id
    ExpressionAttributeValues: {
      ":v1": { S: groupId },
      ":v2": { S: id },
    },
    // Specify the fields you want to retrieve
    ProjectionExpression: "#n, #l, #i, images, floorPlans, latitude, longitude",
    ExpressionAttributeNames: {
      "#n": "name", // Mapping to avoid conflict if 'name' is a reserved keyword
      "#l": "link", // Same for 'link'
      "#i": "id", // Same for 'link'
    },
  };

  try {
    const data = await dynamodb.query(params).promise();

    // Unmarshall and limit images to first 10
    const apartmentData = data.Items.map((item) =>
      AWS.DynamoDB.Converter.unmarshall(item)
    )[0];

    const specificData = {
      id: apartmentData?.id,
      name: apartmentData?.name,
      link: apartmentData?.link,
      images: apartmentData?.images?.slice(0, 10) || [], // Slice images to first 10
      floorPlans: apartmentData?.floorPlans || [], // Ensure floorPlans exist
      latitude: apartmentData?.latitude,
      longitude: apartmentData?.longitude,
    };

    return specificData;
  } catch (error) {
    console.error("Error querying DynamoDB:", error);
  }
};

export const fetchSpecificApartmentName = async (aptId) => {
  const { groupId, apartmentId } = parseId(aptId);

  const params = {
    TableName: TABLE_NAME, // Your table name
    KeyConditionExpression: "apartment_group_id = :v1 AND id = :v2", // Match both apartment_group_id and id
    ExpressionAttributeValues: {
      ":v1": { S: groupId },
      ":v2": { S: apartmentId },
    },
    // Specify the fields you want to retrieve
    ProjectionExpression: "#n, #i, #l",
    ExpressionAttributeNames: {
      "#n": "name", // Mapping to avoid conflict if 'name' is a reserved keyword
      "#i": "id", // Same for 'link'
      "#l": "link", // Same for 'link'
    },
  };

  try {
    const data = await dynamodb.query(params).promise();

    // Unmarshall and limit images to first 10
    const apartmentData = data.Items.map((item) =>
      AWS.DynamoDB.Converter.unmarshall(item)
    )[0];

    const specificData = {
      id: apartmentData?.id,
      name: apartmentData?.name,
      link: apartmentData?.link,
    };

    console.log(specificData)

    return specificData;
  } catch (error) {
    console.error("Error querying DynamoDB:", error);
  }
};

// export const fetchSpecificApartment = async (groupId, id) => {
//   const command = new QueryCommand({
//     TableName: TABLE_NAME,
//     KeyConditionExpression: "apartment_group_id = :group_id AND id = :id",
//     ExpressionAttributeValues: {
//       ":group_id": { S: groupId }, // Partition key (apartment_group_id)
//       ":id": { S: id }, // Sort key (id)
//     },
//   });

//   try {
//     const data = await client.send(command);
//     // Convert DynamoDB's response from AttributeValue format to JSON-like format
//     const apartments = data.Items.map((item) => unmarshall(item));
//     return apartments; // Return the list of apartments
//   } catch (err) {
//     console.error("Error fetching apartments:", err);
//     throw err; // Rethrow error to handle it in the component
//   }
// };
