
export function distToWalkTime(dist) {
  const avgWalkingPacePerKM = 14; // Average walking pace in minutes per km
  const decayFactor = 0.02; // Decay factor, adjust to simulate fatigue
  const adjustedPacePerKM = avgWalkingPacePerKM * Math.exp(decayFactor * dist);
  const totalMinutes = dist * adjustedPacePerKM;

  return Math.ceil(totalMinutes);
}

export function getLocationCoordinates(location) {
  const locations = {
    UIUC: { lat: 40.107543, lng: -88.227252 },
    "Engineering Quad": { lat: 40.111712, lng: -88.227015 },
    CIF: { lat: 40.112433, lng: -88.228244 },
    "Main Quad": { lat: 40.107543, lng: -88.227252 },
    "South Quad": { lat: 40.103614, lng: -88.22719 },
    ARC: { lat: 40.1008585, lng: -88.2362664 },
    "Green Street": { lat: 40.11027, lng: -88.233862 },
  };

  return locations[location] || null;
}

export function calculateTotalRent(floorPlan) {
  if (typeof floorPlan.rent === "string") {
    const price = floorPlan.rent.includes("-")
      ? [
          parseInt(floorPlan.rent.split("-")[0]),
          parseInt(floorPlan.rent.split("-")[1]),
        ]
      : [parseInt(floorPlan.rent)];
    return price;
  } else if (typeof floorPlan.rent === "number") {
    return [floorPlan.rent];
  } else {
    throw new Error("Invalid rent type");
  }
}

export function calculatePerPersonRent(floorPlan) {
  const numPeople =
    floorPlan.perPers === true ? 1 : floorPlan.beds === 0 ? 1 : floorPlan.beds;

  if (typeof floorPlan.rent === "string") {
    const price = floorPlan.rent.includes("-")
      ? [
          Math.ceil(parseInt(floorPlan.rent.split("-")[0]) / numPeople),
          Math.ceil(parseInt(floorPlan.rent.split("-")[1]) / numPeople),
        ]
      : [Math.ceil(parseInt(floorPlan.rent) / numPeople)];
    return price;
  } else if (typeof floorPlan.rent === "number") {
    return [Math.ceil(floorPlan.rent / numPeople)];
  } else {
    throw new Error("Invalid rent type");
  }
}

export function getTotalPriceRange(apt) {
  const prices = apt.floorPlans.map((plan) => calculateTotalRent(plan)).flat();
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  return { min: minPrice, max: maxPrice };
}

export function getPerPersonPriceRange(apt) {
  const prices = apt.floorPlans
    .map((plan) => calculatePerPersonRent(plan))
    .flat();
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  return { min: minPrice, max: maxPrice };
}

export function getBedRange(apt) {
  const beds = apt.floorPlans.map((plan) => plan.beds);

  const minBeds = Math.min(...beds);
  const maxBeds = Math.max(...beds);

  return { min: minBeds, max: maxBeds };
}

export function getBathRange(apt) {
  const baths = apt.floorPlans.map((plan) => plan.bath);

  const minBaths = Math.min(...baths);
  const maxBaths = Math.max(...baths);

  return { min: minBaths, max: maxBaths };
}

function calculatePriceAndBedRange(apt, pricePerPerson) {
  return {
    ...apt,
    priceRange: getTotalPriceRange(apt),
    bedRange: getBedRange(apt),
  };
}

function filterAptsByBedRange(apts, bedRange) {
  return apts
    .filter((apt) => {
      const matchingFloorPlans = apt.floorPlans.filter((plan) => {
        const beds = parseInt(plan.beds, 0); // Ensure radix parameter is correctly set to 10 for decimal
        return beds >= bedRange[0] && beds <= bedRange[1];
      });
      return matchingFloorPlans.length > 0; //&& apt.availability === "Fall 2024";
    })
    .map((apt) => ({
      ...apt,
      floorPlans: apt.floorPlans.filter((plan) => {
        const beds = parseInt(plan.beds, 0);
        return beds >= bedRange[0] && beds <= bedRange[1];
      }),
    }));
}

export function sortByWalkingDistance(apartment_data) {
  const apts =  apartment_data.sort((a, b) => a.distanceKM - b.distanceKM);
  console.log(apts);
  return apts
}

export function sortByMinPrice(apartments) {
  return apartments.sort((a, b) => {
    const aMinRent = Math.min(...a.floorPlans.map(plan => calculatePerPersonRent(plan)[0]));
    const bMinRent = Math.min(...b.floorPlans.map(plan => calculatePerPersonRent(plan)[0]));
    return aMinRent - bMinRent;
  });
}

export function sortByMaxPrice(apartments) {
  return apartments.sort((a, b) => {
    const aMinRent = Math.max(...a.floorPlans.map(plan => calculatePerPersonRent(plan)[0]));
    const bMinRent = Math.max(...b.floorPlans.map(plan => calculatePerPersonRent(plan)[0]));
    return bMinRent - aMinRent ;
  });
}



function filterAptsByPriceRange(apts, priceRange) {
  const thresh = 50;
  return apts.filter((apt) => {
    const matchingFloorPlans = apt.floorPlans.filter((plan) => {
      const rentRange = calculatePerPersonRent(plan);
      const minRent = rentRange[0];
      const maxRent = rentRange.length > 1 ? rentRange[1] : rentRange[0];
      return (
        minRent <= priceRange[1] + thresh && maxRent >= priceRange[0] - thresh
      );
    });
    return matchingFloorPlans.length > 0;
  });
  // .map((apt) => ({
  //   ...apt,
  //   floorPlans: apt.floorPlans.filter((plan) => {
  //     const rentRange = calculateTotalRent(plan);
  //     const minRent = rentRange[0];
  //     const maxRent = rentRange.length > 1 ? rentRange[1] : rentRange[0];
  //     return (
  //       minRent >= priceRange[0] &&
  //       maxRent <= priceRange[1]
  //     );
  //   }),
  // }));
}

function filterAptsByWalkingDistance(apts, maxWalkingDistance, userLocation) {
  const locCords = getLocationCoordinates(userLocation);
  return apts
    .map((apt) => {
      if (apt.latitude && apt.longitude) {
        const distance = calculateDistance(
          locCords.lat,
          locCords.lng,
          apt.latitude,
          apt.longitude
        );

        return {
          ...apt,
          distanceKM: distance, // Add distance property to apt object
        };
      } else {
        return {
          ...apt,
          distanceKM: "NA", // Add distance property as 'NA'
        };
      }
    })
    .filter(
      (apt) =>
        apt.distanceKM === "NA" ||
        distToWalkTime(apt.distanceKM) <= maxWalkingDistance + 2
    );
}

const notAvailableStatuses = [
  "no units available",
  "unavailable",
  "no available units",
  "not available",
  "sold out",
  "fully leased",
];

let filterAvailableFloorPlans = (apts) => {
  return apts
    .map((apt) => {
      const availableFloorPlans = apt.floorPlans.filter((plan) => {
        if (!plan.availability) {
          return false;
        }

        const planAvailability = plan.availability.toLowerCase();
        const notAvailable = notAvailableStatuses.some((status) =>
          planAvailability.includes(status)
        );
        return !notAvailable;
      });

      return {
        ...apt,
        floorPlans: availableFloorPlans,
      };
    })
    .filter((apt) => apt.floorPlans.length > 0);
};

let filterUnavailableFloorPlans = (apts) => {
  return apts
    .map((apt) => {
      const availableFloorPlans = apt.floorPlans.filter((plan) => {
        if (!plan.availability) {
          return true;
        }

        const planAvailability = plan.availability.toLowerCase();
        const notAvailable = notAvailableStatuses.some((status) =>
          planAvailability.includes(status)
        );
        return notAvailable;
      });

      return {
        ...apt,
        floorPlans: availableFloorPlans,
      };
    })
    .filter((apt) => apt.floorPlans.length > 0);
};

export function filterInitialApts(apts) {
  var filteredApts = filterValidRentFloorPlans(apts);
  return filteredApts;
}

let filterValidRentFloorPlans = (apts) => {
  return apts
    .map((apt) => {
      // Check if apt.floorPlans is defined, otherwise default to empty array
      const validFloorPlans = (apt.floorPlans || []).filter((plan) => {
        const rentValue = parseInt(plan.rent, 10); // Convert rent to integer
        return rentValue;
      });

      return {
        ...apt,
        floorPlans: validFloorPlans,
      };
    })
    .filter((apt) => apt.floorPlans.length > 0);
};

export function sortPremiumApartments(apartment_data) {

  const prioritizedIds = [
    "herechampaign", 
    "seven07",
    "deancampustown", 
    "hubchampaign", 
    "212east", 
    "legacy202", 
    "octave",
    "icon",
    "thevue", 
    "yugochampaign", 
    "yugourbana",
    "309green", 
    "suitesatthird", 
    "lofts54",
    "thetoweratthird",
    "campuscircle", 
    "maywood",
    "lancaster", 
    "latitude",
    
  ];
  
  const remainingIds = [
    "pacificaongreen", 
    "burnham310", 
    "westquad", 
  ];
  
  const ids = [...prioritizedIds, ...remainingIds];
  
  // const ids = [
  //   "herechampaign",
  //   "seven07",
  //   "deancampustown",
  //   "hubchampaign",
  //   "pacificaongreen",
  //   "burnham310",
  //   "212east",
  //   "legacy202",
  //   "westquad",
  //   "yugochampaign",
  //   "yugourbana",
  //   "octave",
  //   "greenstreettowers",
  //   "icon",
  //   "309green",
  //   "thetoweratthird",
  //   "suitesatthird",
  //   "lofts54",
  //   "thevue",
  //   "maywood",
  //   "lancaster",
  //   "campuscircle",
  //   "latitude",
  // ];

  return apartment_data.sort((a, b) => {
    let indexA = ids.indexOf(a.id);
    let indexB = ids.indexOf(b.id);

    if (indexA === -1) indexA = Infinity; // If id not found in ids, place it at the end
    if (indexB === -1) indexB = Infinity;

    return indexA - indexB;
  });
}

export function sortPremiumGroupApartments(apartment_data) {
  const group1 = [
    "academy/501-s-sixth",
    "ugroup/305-daniel",
    "bankier/519-e-green-st",
    "academy/1008-s-fourth",
    "academy/307-e-daniel",
    "academy/908-s-first",
    "ugroup/75-armory",
    "ugroup/901-western",
    "ugroup/507-second",
  ];
  const group2 = [];

  const customSort = (a, b) => {
    const indexA = group1.includes(a.id)
      ? group1.indexOf(a.id)
      : group2.includes(a.id)
      ? group1.length + group2.indexOf(a.id)
      : group1.length + group2.length;
    const indexB = group1.includes(b.id)
      ? group1.indexOf(b.id)
      : group2.includes(b.id)
      ? group1.length + group2.indexOf(b.id)
      : group1.length + group2.length;

    return indexA - indexB;
  };

  return apartment_data.sort(customSort);
}

function filterAptsByPrivateBath(apts) {
  console.log("filtered private bath");
  return apts
    .map((apt) => {
      const privateBathFloorPlans = apt.floorPlans.filter(
        (plan) => plan.bath >= plan.beds
      );
      return {
        ...apt,
        floorPlans: privateBathFloorPlans,
      };
    })
    .filter((apt) => apt.floorPlans.length > 0);
}

function sortApartmentsByDistance(apartments) {
  return apartments.sort((a, b) => a.distanceKM - b.distanceKM);
}

export let filterApts = (apts, filters) => {
  var filteredApts = apts;
  if (filters.availability === "Fall 2025") {
    filteredApts = filterAvailableFloorPlans(filteredApts);
  } else if (filters.availability === "Sold Out") {
    filteredApts = filterUnavailableFloorPlans(filteredApts);
  }
  filteredApts = filterAptsByBedRange(filteredApts, filters.bedRange);
  // filterApts = filterApts
  filteredApts = filterAptsByWalkingDistance(
    filteredApts,
    filters.distRange,
    filters.selectedLocation
  );

  filteredApts = filterAptsByPriceRange(filteredApts, filters.priceRange);
  if (filters.privateBathroom) {
    filteredApts = filterAptsByPrivateBath(filteredApts);
  }
  // filteredApts = sortApartmentsByDistance(filteredApts);
  console.log("Filtered Apartments");
  return filteredApts;
};

function sortApts(apartmentData, sortCriteria) {
  const sortedApts = [...apartmentData]; // Create a copy of the data to sort

  if (sortCriteria === "price") {
    sortedApts.sort((a, b) => a.price - b.price);
  } else if (sortCriteria === "distance") {
    sortedApts.sort((a, b) => a.distance - b.distance);
  } else if (sortCriteria === "ranking") {
    sortedApts.sort((a, b) => b.ranking - a.ranking); // Assuming higher ranking is better
  }

  return sortedApts;
}

export function calculateDistance(lat1, lon1, lat2, lon2) {
  const p = Math.PI / 180;
  const c = Math.cos;
  const a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

const getPriceRangeString = (plans) => {
  const extractPrices = (rent) => {
    if (typeof rent === "string") {
      if (rent.includes("-")) {
        return rent.split("-").map(Number);
      } else if (/^\d+$/.test(rent)) {
        return [parseInt(rent, 10)];
      }
    } else if (typeof rent === "number") {
      return [rent];
    }
    return [];
  };

  const prices = plans.flatMap((plan) => extractPrices(plan.rent));
  if (prices.length === 0) {
    return "Call for Pricing";
  }

  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  if (isNaN(minPrice) || isNaN(maxPrice)) {
    return "Call for Pricing";
  }

  return minPrice === maxPrice ? `$${minPrice}` : `$${minPrice}-$${maxPrice}`;
};

const getBedRangeString = (plans) => {
  const beds = plans.map((plan) => plan.beds);
  const minBeds = Math.min(...beds);
  const maxBeds = Math.max(...beds);

  if (minBeds === 0) {
    return "Studio";
  }

  if (minBeds === maxBeds) {
    return `${minBeds} Bed${minBeds > 1 ? "s" : ""}`;
  }

  return `${minBeds}-${maxBeds} Beds`;
};

const getBathRangeString = (plans) => {
  const baths = plans.map((plan) => plan.bath);
  const minBaths = Math.min(...baths);
  const maxBaths = Math.max(...baths);

  if (minBaths === maxBaths) {
    return `${minBaths} Bath${minBaths > 1 ? "s" : ""}`;
  }

  return `${minBaths}-${maxBaths} Baths`;
};

export const getPriceDescString = (floorPlans) => {
  const priceRange = getPriceRangeString(floorPlans);
  const bedRange = getBedRangeString(floorPlans);
  const bathRange = getBathRangeString(floorPlans);
  const combinedString = `${priceRange} • ${bedRange} • ${bathRange}`;
  return combinedString;
};
