import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainHeader from "../../components/Headers/MainHeader";
import ImageGallery from "../../components/ImageGallery";
import { ReactComponent as ChevronLeft } from "../../data/imgs/chevron-left.svg";
import Footer from "../../components/Headers/Footer";
import PhotoModal from "../../components/PhotoModal.js";
import DatePicker from "react-datepicker";
import { differenceInDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase/index";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import emailjs from "@emailjs/browser";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { format } from "date-fns";
import { sendSubleaseHostEmail, sendSubleaseUserConfirmationEmail } from "../../backend/emailAPI";
import { fetchUserData} from '../../backend/dbAPI';

function formatDate(timestamp) {
  if (!timestamp || !timestamp.toDate) {
    return "ERROR"; // or any default string you prefer
  } else {
    const date = timestamp.toDate(); // Converts Firestore timestamp to JavaScript Date object
    return format(date, "MM-dd-yyyy");
  }
}

function SubleaseDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [apartment, setApartment] = useState(null);
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const [hostProfile, setHostProfile] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    pfpImg: "",
    schoolYear: "",
    major: "",
    school: "UIUC",
    contact: {
      email: "",
      phoneNumber: "",
      instagramHandle: "",
    },
  });
  const [sublease, setSublease] = useState([null]);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const cache = {};
  const [user] = useAuthState(auth);

  const [userProfile, setUserProfile] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    instagramHandle: "",
    linkedinHandle: "",
    preferredContact: "",
    profilePic: "",
    description: ""
  });
  
  useEffect(() => {
    const getUserData = async () => {
      try {
        const fetchedUserData = await fetchUserData(user.uid);
        if (fetchedUserData) {
          setUserProfile({
            firstName: fetchedUserData.firstName || '',
            lastName: fetchedUserData.lastName || '',
            phone: fetchedUserData.phone || '',
            email: fetchedUserData.email || '',
            instagramHandle: fetchedUserData.instagramHandle || '',
            linkedinHandle: fetchedUserData.linkedinHandle || '',
            preferredContact: fetchedUserData.preferredContact || '',
            profilePic: fetchedUserData.profilePicture || null,
            description: fetchedUserData.description || '',
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    getUserData();
  }, [user.uid]);

  

  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const toggleShowAllPhotos = () => {
    setShowAllPhotos(!showAllPhotos);
  };
  const fetchSublease = async () => {
    if (cache[id]) {
      return cache[id];
    }
    try {
      const subleaseDocRef = doc(db, "subleases", id);
      const subleaseDocSnap = await getDoc(subleaseDocRef);
      if (subleaseDocSnap.exists()) {
        const subleaseData = {
          id: subleaseDocSnap.id,
          ...subleaseDocSnap.data(),
        };

        // Fetch the roommates collection inside the sublease document
        const roommatesCollectionRef = collection(subleaseDocRef, "roommates");
        const roommatesCollectionSnap = await getDocs(roommatesCollectionRef);
        const roommates = roommatesCollectionSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Add the roommates data to the sublease data
        subleaseData.roommates = roommates;

        // Set the state and cache the result
        setSublease(subleaseData);
        cache[id] = subleaseData;

        console.log(subleaseData);
        setLoading(false);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching sublease:", error);
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      const storage = getStorage();
      const listRef = ref(storage, `sublease_images/${id}/`); // Adjust path as needed
      try {
        const res = await listAll(listRef);
        const urls = await Promise.all(
          res.items.map((itemRef) => getDownloadURL(itemRef))
        );
        setImageUrls(urls);
      } catch (error) {
        console.error("Error fetching images: ", error);
      }
    };
    fetchImages();
  }, [id]);

  useEffect(() => {
    fetchSublease();
  }, []);

  useEffect(() => {
    if (sublease.userId) {
      const fetchHost = async () => {
        const docRef = doc(db, "users", sublease.userId);
        const docSnap = await getDoc(docRef);
        try {
          if (docSnap.exists()) {
            const data = docSnap.data();
            const hostData = {
              userId: sublease.userId,
              firstName: data.firstName,
              lastName: data.lastName,
              pfpImg: data.profilePicture,
              schoolYear: data.year,
              major: data.major,
              school: "UIUC",
              contact: {
                email: data.email,
                phoneNumber: sublease.phoneNumber,
                instagramHandle: data.instagramHandle,
              },
            };
            setHostProfile(hostData);
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error getting document:", error);
        } finally {
        }
      };

      fetchHost();
    }
  }, [sublease.userId]);

  const handleShareClick = () => {
    console.log(`${apartment.id} Share clicked`);
  };

  const handleSaveClick = () => {
    console.log(`${apartment.id} Save clicked`);
  };

  const navigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchImages = async () => {
      const storage = getStorage();
      const listRef = ref(storage, `${id}/`);
      try {
        const res = await listAll(listRef);
        const urls = await Promise.all(
          res.items.map((itemRef) => getDownloadURL(itemRef))
        );
        setImageUrls(urls);
      } catch (error) {
        console.error("Error fetching images: ", error);
      }
    };

    fetchImages();
  }, [id]);

  if (!sublease) {
    return <div>Loading...</div>;
  }

  const Header = () => {
    return (
      <>
        <div className="hidden md:block">
          <MainHeader />
        </div>
        <div>
          <div className="flex items-center justify-between px-6 py-4 bg-white md:hidden">
            <ChevronLeft
              className="w-6 h-6 cursor-pointer"
              onClick={navigateBack}
            />
            <div className="flex gap-x-8 text-gray-700">
              <button onClick={handleShareClick} className="flex items-center">
                <i className="fa-solid fa-arrow-up-from-bracket fa-sm"></i>
              </button>
              <button onClick={handleSaveClick} className="flex items-center">
                <i className="fa-regular fa-heart fa-sm"></i>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const Title = ({ title }) => {
    return (
      <div className="flex justify-between items-center pb-4 pt-6 hidden md:flex">
        <h1 className="text-2xl font-semibold">{title}</h1>
        <div className="flex gap-x-8 text-gray-700">
          <button onClick={handleShareClick} className="flex items-center">
            <i className="fa-solid fa-arrow-up-from-bracket fa-sm"></i>
            <p className="text-sm font-semibold ml-1">Share</p>
          </button>
          <button onClick={handleSaveClick} className="flex items-center">
            <i className="fa-regular fa-heart fa-sm"></i>
            <p className="text-sm font-medium ml-1">Save</p>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white relative">
      <Header />
      <div className="container max-w-6xl mx-auto md:px-6">
        <Title title={sublease.apartmentName} />
        <ImageGallery images={imageUrls} onClick={toggleShowAllPhotos} />
        <div className="flex flex-col px-6 md:flex-row md:px-0">
          <div className="w-full pr-0 md:w-2/3 md:pr-12">
            <SubHeader
              name={sublease.apartmentName}
              subHeader={
                hostProfile.firstName + "'s Room at " + sublease.apartmentName
              }
              sublease={sublease}
            />
            <Divider />
            <HostProfile user={hostProfile} userProfile={userProfile} />
            <Description
              description={sublease.description}
              toggleShowAllPhotos={toggleShowAllPhotos}
            />
            <Divider />
            <Availability start={sublease.startDate} end={sublease.endDate} />
            <Divider />
            <GenderRequirement genderRequirement={sublease.genderRequirement} />
            <Divider />
            {sublease.roommates && Array.isArray(sublease.roommates) && (
              <Roommates roommates={sublease.roommates} />
            )}
            <Divider />
            <RentAndFees rent={sublease.rent} feeDesc={sublease.feeInfo} />
            {/* <Divider /> */}
            {/* <ContactHost user={hostProfile} /> */}
            <Divider />
            <Location address={sublease.address} />
          </div>
          <div className="hidden md:block w-full md:w-1/3 rounded-lg">
            <h1 className="text-lg font-semibold py-4 pt-6 text-end">
              92 Viewed 🔥
            </h1>
            <aside class="self-start sticky top-24 mt-0 col-span-1">
              {!isMobile() && (
                <ReservationSummary
                  hostProfile={hostProfile}
                  userProfile={userProfile}
                  communityLink={sublease.websiteLink}
                  rent={sublease.rent}
                  start={sublease.startDate}
                  end={sublease.endDate}
                />
              )}
            </aside>
          </div>
          <div className="fixed bottom-0 left-0 w-full bg-white text-black p-4 md:hidden border-t border-gray-300">
            <div className="flex w-full justify-between">
              <div></div>
              <button className="bg-logo_blue text-white text-sm font-semibold py-2 px-8 rounded-md">
                Connect with {hostProfile.firstName}
              </button>
            </div>
          </div>
        </div>
        <Divider />
      </div>
      <PhotoModal
        showModal={showAllPhotos}
        toggleModal={toggleShowAllPhotos}
        images={imageUrls}
      />
      <Footer />
    </div>
  );
}

const RentAndFees = ({ rent, feeDesc }) => {
  return (
    <div className="">
      <div className="">
        <h1 className="text-xl font-semibold pb-2">Rent</h1>
        <span className="text-md font-medium text-black">${rent}</span>
        <span className="text-sm font-medium ml-1">/month</span>
      </div>
      {feeDesc && (
        <div className="mt-4">
          <h1 className="text-xl font-semibold pb-2">Fees</h1>
          <span className="text-md font-medium text-black">{feeDesc}</span>
        </div>
      )}
    </div>
  );
};

const ReservationSummary = ({
  communityLink,
  hostProfile,
  userProfile,
  rent,
  start,
  end,
}) => {
  const [showHostProfile, setShowHostProfile] = useState(false);

  const handleConnectWithHost = () => {
    setShowHostProfile(true);
  };

  const handleCloseHostProfile = () => {
    setShowHostProfile(false);
  };

  const handleCommunityLinkClick = () => {
    if (communityLink) {
      window.open(communityLink, "_blank");
    }
  };

  return (
    <div className="max-w-sm p-6 bg-white shadow-md border rounded-lg">
      <div className="text-xl font-semibold pb-4">
        {/* <span className="line-through text-gray-500">$1100</span>{" "} */}
        <span className="text-black">${rent}</span>
        <span className="text-sm font-regular ml-1">month</span>
      </div>
      <div className="mt-4">
        <DateRangePicker start={start} end={end} />
        <button
          onClick={handleConnectWithHost}
          className="w-full bg-gradient-blue-r text-white py-4 rounded-lg font-semibold mt-8"
        >
          Connect with Host
        </button>

        {communityLink && (
          <div className="text-center mt-2">
            <button
              className=" text-sm underline text-gray-500"
              onClick={handleCommunityLinkClick}
            >
              Visit Community Website
            </button>
          </div>
        )}

        {showHostProfile && (
          <HostProfileModal
            hostProfile={hostProfile}
            userProfile={userProfile}
            onClose={handleCloseHostProfile}
          />
        )}
      </div>
    </div>
  );
};

const DateRangePicker = ({ start, end }) => {
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const handleStartDateChange = (date) => {
    if (differenceInDays(endDate, date) < 30) {
      alert("The selected date range must be at least 30 days.");
      return;
    }
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    if (differenceInDays(date, startDate) < 30) {
      alert("The selected date range must be at least 30 days.");
      return;
    }
    setEndDate(date);
  };

  return (
    <div className="w-full p-4 bg-white border rounded-lg">
      <div className="grid grid-cols-2 gap-2">
        <div className="text-center">
          <div className="font-bold">From</div>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={new Date(start)}
            endDate={new Date(end)}
            minDate={new Date(start)}
            maxDate={new Date(end)}
            dateFormat="MMM d, yyyy"
            className="w-full mt-2 p-2 border rounded"
          />
        </div>
        <div className="text-center">
          <div className="font-bold">To</div>
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            selectsEnd
            startDate={new Date(start)}
            endDate={new Date(end)}
            minDate={new Date(start)}
            maxDate={new Date(end)}
            dateFormat="MMM d, yyyy"
            className="w-full mt-2 p-2 border rounded"
          />
        </div>
      </div>
    </div>
  );
};

const Divider = () => {
  return <hr className="my-8" />;
};

const Location = ({ address }) => {
  return (
    <div className="">
      <h2 className="text-xl font-semibold mb-4">Location</h2>
      <div className="flex items-center gap-x-4">
        <i className="fa-solid fa-map-marker-alt fa-lg text-logo_blue"></i>
        <div>
          <div className="font-medium">{address}</div>
        </div>
      </div>
    </div>
  );
};

const Roommates = ({ roommates }) => {
  if (!roommates) {
    return <div>No roommates found.</div>;
  }

  return (
    <div className="">
      <h2 className="text-xl font-semibold mb-4">
        Your roommates ({roommates.length})
      </h2>
      {roommates.map((roommate, index) => (
        <div key={index} className="flex items-center mb-4">
          <div className="w-12 h-12 flex justify-center items-center rounded-full mr-2">
            <i className="fa-solid fa-user fa-lg"></i>
          </div>
          <div>
            <div className="font-semibold">Roommate {index + 1}</div>
            <div className="text-gray-600">
              {roommate.gender} • {roommate.age} years old •{" "}
              {roommate.occupation}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const HostProfile = ({ user, userProfile }) => {
  const majorYearFormat = `${user.major} ${user.schoolYear} at ${user.school}`;
  const nameFormat = `${user.firstName}'s place`;
  const [showHostProfile, setShowHostProfile] = useState(false);

  const handleCloseHostProfile = () => {
    setShowHostProfile(false);
  };

  const handleCommunityLinkClick = () => {
    setShowHostProfile(true);
  };
  return (
    <div className="items-center justify-between">
      <div className="flex items-center">
        <img
          src={user.pfpImg ||
            "https://cdn-icons-png.flaticon.com/512/149/149071.png"}
          alt="profile"
          className="w-16 h-16 rounded-full object-cover"
        />
        <div className="ml-4">
          <h2 className="text-xl font-semibold">{nameFormat}</h2>
          <h2 className="text-sm text-gray-500">{majorYearFormat}</h2>
          <div className="flex space-x-2 mt-2 text-xs">
            {user.emailVerified && (
              <div className="flex items-center p-1 bg-black rounded-lg text-white px-2">
                <i className="fa fa-check mr-2"></i>
                School Email Verified
              </div>
            )}
            {user.phoneVerified && (
              <div className="flex items-center p-1 bg-black rounded-lg text-white px-2">
                <i className="fa fa-check mr-2"></i>
                Phone Verified
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="pt-8">
        <button
          className="px-4 py-3 rounded-lg text-white text-md font-medium bg-gradient-blue-r"
          onClick={handleCommunityLinkClick}
        >
          Contact {user.firstName}
        </button>

        {showHostProfile && (
          <HostProfileModal
            hostProfile={user}
            userProfile={userProfile}
            onClose={handleCloseHostProfile}
          />
        )}
      </div>
    </div>
  );
};

const ContactHost = ({ user }) => {
  const majorYearFormat = `${user.major} ${user.schoolYear} at ${user.school}`;
  const nameFormat = `${user.firstName}'s place`;
  return (
    <div className="items-center justify-between">
      <h2 className="text-xl font-semibold mb-4">Your Host</h2>
      <div className="flex items-center py-2">
        <img
          src={user.pfpImg}
          alt="profile"
          className="w-16 h-16 rounded-full"
        />
        <div className="ml-4">
          <h2 className="text-lg font-semibold">{nameFormat}</h2>
          <h2 className="text-sm text-gray-500">{majorYearFormat}</h2>
          <div className="flex space-x-2 mt-2 text-xs">
            {user.emailVerified && (
              <div className="flex items-center p-1 bg-black rounded-lg text-white px-2">
                <i className="fa fa-check mr-2"></i>
                School Email Verified
              </div>
            )}
            {user.phoneVerified && (
              <div className="flex items-center p-1 bg-black rounded-lg text-white px-2">
                <i className="fa fa-check mr-2"></i>
                Phone Verified
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="pt-8">
        <button className="px-4 py-3 rounded-lg text-sm font-medium border-2 border-black">
          Send to {user.firstName} a question
        </button>
      </div>
    </div>
  );
};

const Availability = ({ start, end }) => {
  const formattedStartDate = start;
  const formattedEndDate = end;

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Availability</h2>
      <div className="flex items-center space-x-8">
        <i className="fa fa-calendar text-xl"></i>
        <div className="flex items-center space-x-8">
          <div>
            <h2 className="text-md font-semibold pb-1">Available From</h2>
            <h2 className="text-md font-medium text-gray-500">{start}</h2>
          </div>
          <div>
            <h2 className="text-md font-semibold pb-1">Available To</h2>
            <h2 className="text-md font-medium text-gray-500">{end}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

const SubHeader = ({ name, subHeader, sublease }) => {
  const floorPlan = sublease.configuration;
  const floorPlanNameFormat = `Floorplan: ${floorPlan}`;
  const bedBathFormat = `${sublease.bedrooms} Bed • ${sublease.bathrooms} Bath`;

  return (
    <>
      {/* SubHeader for small screens */}
      <div className="container pt-4 md:hidden">
        <h1 className="text-2xl font-semibold mb-2">{name}</h1>
        <h1 className="text-lg font-medium">{subHeader}</h1>
        <div className="text-md font-regular pt-1">{floorPlanNameFormat}</div>
        <div className="text-md font-regular">{bedBathFormat}</div>
      </div>

      {/* SubHeader for larger screens */}
      <div className="container pt-6 hidden md:block">
        <h1 className="text-xl font-semibold">{subHeader}</h1>
        <div className="text-gray-600 pt-1">
          <div className="text-md font-medium">{floorPlanNameFormat}</div>
          <div className="text-md font-medium">{bedBathFormat}</div>
        </div>
      </div>
    </>
  );
};

const Description = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="container">
      <Divider />
      <div className="mb-4">
        <p className={`overflow-hidden ${isExpanded ? "" : "line-clamp-3"}`}>
          {description}
        </p>
      </div>
      <button onClick={toggleDescription} className="font-medium underline">
        {isExpanded ? "Show Less" : "Show More >"}
      </button>
    </div>
  );
};

const GenderRequirement = ({ genderRequirement }) => {
  console.log(genderRequirement);
  const allGenderRequirements = [
    { label: "Male", icon: "♂" },
    { label: "Female", icon: "♀" },
    { label: "Others", icon: "⚥" },
  ];

  const renderGenderRequirements = () => {
    if (genderRequirement === "Any") {
      return allGenderRequirements;
    }
    return allGenderRequirements.filter(
      (option) => option.label === genderRequirement
    );
  };
  const requirementsToDisplay = renderGenderRequirements();
  return (
    <div className="">
      <h2 className="text-xl font-semibold mb-4">Gender requirement: </h2>
      <div className="flex space-x-4">
        <p className="flex text-lg font-medium items-center space-x-2 border border-gray-300 rounded-md px-4 py-2 hover:bg-gray-100">
          <span>{genderRequirement}</span>
        </p>
      </div>
    </div>
  );
};

const HostProfileModal = ({  hostProfile, userProfile, onClose }) => {
  const [message, setMessage] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {

    const formData = {
      // message,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      email: userProfile.email,
      phone: userProfile.phone, 
      description: message
    };

    const hostData = {
      firstName: hostProfile.firstName,
      lastName: hostProfile.lastName,
      email: hostProfile.email,
      phone: hostProfile.phone, 
      description: message
    }

    try {
      // console.log(userData.name)
      console.log(user.email)
      console.log(userProfile.phone)
      // const response = await sendSubleaseHostEmail(formData, "karan.kashyap.4899@gmail.com");
      const response = await sendSubleaseHostEmail(formData, hostProfile.contact.email);
      // const confirmation = await sendSubleaseUserConfirmationEmail(formData, hostData, userProfile.email);
      if (response.ok) {
        console.log("Message submitted:", message);
        setIsSubmitted(true);
      } else {
        const result = await response.json();
        console.log("Failed to send email:", result.message);
      }
    } catch (err) {
      console.log("Failed to send email", err);
    }


  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-full h-screen">
      <div className={`w-full ${isSubmitted ? 'md:max-w-lg' : 'md:max-w-7xl'} bg-white rounded-lg shadow-lg overflow-hidden`}>
        <div className="relative">
          <button
            onClick={onClose}
            className="absolute top-2 right-4 text-gray-600 text-2xl hover:text-gray-800"
          >
            &times;
          </button>
          <div className="md:flex">
            {isSubmitted ? (
              <div className="md:w-full p-8">
                <h2 className="text-2xl font-semibold mb-4">Email Submitted</h2>
                <p className="text-gray-600">Your message has been successfully sent to the host.</p>
                <button
                  onClick={onClose}
                  className="mt-4 w-full bg-logo_blue text-white py-2 rounded-lg font-semibold hover:bg-blue-700"
                >
                  Back to Listing
                </button>
              </div>
            ) : (
              <div className="md:flex">
                <div className="md:w-2/3 p-8">
                  <div className="flex items-center mb-6">
                    <img
                      src={hostProfile.pfpImg}
                      alt="profile"
                      className="w-20 h-20 rounded-full"
                    />
                    <div className="ml-4">
                      <h2 className="text-2xl font-semibold">
                        {hostProfile.firstName} {hostProfile.lastName}
                      </h2>
                      <p className="text-sm text-gray-500">
                        {hostProfile.major} {hostProfile.schoolYear} at{" "}
                        {hostProfile.school}
                      </p>
                    </div>
                  </div>
                  <textarea
                    className="w-full h-96 p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-logo_blue"
                    placeholder="Ask a question..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  <button
                    onClick={handleSubmit}
                    className="mt-4 w-full bg-logo_blue text-white py-2 rounded-lg font-semibold hover:bg-blue-700"
                  >
                    Submit Question
                  </button>
                </div>
                <div className="md:w-1/3 p-8 bg-gray-50">
                  <h3 className="text-lg font-semibold mb-4">
                    Contact Information
                  </h3>

                  <p className="text-sm mb-4">
                    Interested in booking, setting up a tour, or applying for the
                    sublease? Contact the student directly! The current tenant will
                    take the process from there after Abode has connected you
                    together.
                  </p>
                  <p className="text-sm mb-2">
                    <span className="font-semibold">Contact Email: </span>
                    {hostProfile.contact.email}
                  </p>
                  <div className="flex flex-col text-sm">
                    {hostProfile.contact.instagramHandle && (
                      <p className="mb-2">
                        Instagram: {hostProfile.contact.instagramHandle}
                      </p>
                    )}
                    {hostProfile.contact.linkedinHandle && (
                      <p className="mb-2">
                        LinkedIn: {hostProfile.contact.linkedinHandle}
                      </p>
                    )}
                    {hostProfile.contact.wechatHandle && (
                      <p className="mb-2">
                        WeChat: {hostProfile.contact.wechatHandle}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubleaseDetail;
