import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainHeader from "../components/Headers/MainHeader";
import Slider from "react-slick";
import { ReactComponent as ChevronLeft } from "../data/imgs/chevron-left.svg";
import Footer from "../components/Headers/Footer";
import PhotoModal from "../components/PhotoModal.js";
import Reviews from "../components/Reviews/Reviews.js";
import { auth } from "../firebase/index";
import { useAuthState } from "react-firebase-hooks/auth";
import ShareModal from "../components/ShareModal";

import ApplyNowForm from "../components/ApplyNowForm";
import pfpImages from "../data/imgs/pfpImgs.jpg";
import AppContext from "../AppContext";
import { getPriceDescString } from "../backend/utils";

// import LocationCard from "../components/Map/LocationCard";
import ImageGallery from "../components/ImageGallery";

import floorplanPlaceholderImg from "../data/imgs/floorplan_placeholder.jpg";
import { fetchSpecificApartment } from "../backend/dynamoAPI";

import { logEvent, analytics } from "../firebase/index";

function ApartmentDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [apartment, setApartment] = useState(null);
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const { favorites, addToFavorites, removeFromFavorites } =
    useContext(AppContext);
  const [user, loading, error] = useAuthState(auth);
  const [isFavorited, setIsFavorited] = useState(favorites.includes(id));
  const [showAlert, setShowAlert] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [priceDesc, setPriceDesc] = useState("");

  function handleApplyNowClick() {
    navigate(`/apply-now/${id}`);
  }

  const handleOpenModal = () => {
    setIsShareModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsShareModalOpen(false);
  };

  const shareUrl = window.location.href;

  const toggleShowAllPhotos = () => {
    setShowAllPhotos(!showAllPhotos);
  };

  const handleShareClick = async () => {
    // if (navigator.share) {
    //   try {
    //     await navigator.share({
    //       title: apartment?.name,
    //       text: apartment.name + ": " + priceDesc,
    //       url: shareUrl,
    //     });
    //     console.log("Share successful");
    //   } catch (error) {
    //     console.error("Error sharing:", error);
    //   }
    // } else {
    // console.log("Web Share API not supported in this browser.");
    handleOpenModal();
    //   console.log(`${apartment.id} Share clicked`);
    // }
  };

  const handleSaveClick = () => {
    if (user) {
      if (!isFavorited) {
        addToFavorites(user.uid, apartment.id);
      } else {
        removeFromFavorites(user.uid, apartment.id);
      }
      setIsFavorited(!isFavorited);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 7000);
    } else {
      alert("Please login to save apartments");
    }
    console.log(`${apartment.id} Save clicked`);
  };

  const navigateBack = () => {
    navigate("/listings");
  };

  useEffect(() => {
    const fetchApartmentData = async () => {
      try {
        const jsonData = await fetchSpecificApartment("single_apartments", id);
        setApartment(jsonData);
      } catch (error) {
        console.error("Error fetching apartment data:", error);
      }
    };

    fetchApartmentData();
  }, [id]); // Only depends on `id`, not `apartment`

  useEffect(() => {
    if (apartment) {
      setPriceDesc(getPriceDescString(apartment.floorPlans));
    }
  }, [apartment]); // Update priceDesc when apartment changes

  useEffect(() => {
    setIsFavorited(favorites.includes(id));
  }, [favorites, id]);

  if (!apartment) {
    return <div>Loading...</div>;
  }

  const Title = () => {
    return (
      <div className="flex justify-between items-center pb-4 pt-6 hidden md:flex">
        <div className="flex items-center transition-colors duration-300">
              <div className="p-2 hover:bg-gray-100 rounded-full">
                <ChevronLeft
                  className="w-6 h-6 cursor-pointer"
                  onClick={navigateBack}
                />
              </div>
              <h1 className="text-2xl font-semibold ml-1">
                {apartment.name}
              </h1>
            </div>
        <div className="flex">
          <button
            onClick={handleShareClick}
            className="flex items-center px-4 py-2 rounded-lg hover:bg-gray-100"
          >
            <i className="fa-solid fa-arrow-up-from-bracket fa-md"></i>
            <p className="text-sm font-semibold ml-2">Share</p>
          </button>
          <button
            onClick={handleSaveClick}
            className="flex items-center px-4 py-2 rounded-lg hover:bg-gray-100"
          >
            {isFavorited ? (
              <div className="flex items-center">
                <i className="fa-solid text-red-500 fa-heart fa-md"></i>
                {/* <RedHeartIcon className="h-4" /> */}
                <p className="text-sm font-medium ml-2 underline">Saved</p>
              </div>
            ) : (
              <div className="flex items-center">
                <i className="fa-regular fa-heart fa-md"></i>
                <p className="text-sm font-medium ml-2 underline">Save</p>
              </div>
            )}
          </button>
        </div>
      </div>
    );
  };

  const Header = () => {
    return (
      <>
        <div className="hidden md:block">
          <MainHeader />
        </div>
        <div>
          <div className="flex items-center justify-between bg-white md:hidden">
            <button className="p-4" onClick={navigateBack}>
              <ChevronLeft className="w-6 h-6" />
            </button>

            <div className="flex text-gray-700">
              <button
                onClick={handleShareClick}
                className="flex items-center p-4"
              >
                <i className="fa-solid fa-arrow-up-from-bracket fa-md"></i>
              </button>
              <button
                onClick={handleSaveClick}
                className="flex items-center p-4"
              >
                {isFavorited ? (
                  <i className="fa-solid text-red-500 fa-heart fa-md"></i>
                ) : (
                  <i className="fa-regular fa-heart fa-md"></i>
                )}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="bg-white relative">
      <Header />
      <div className="container max-w-6xl mx-auto md:px-6">
        <Title title={apartment.name} />
        <ImageGallery images={apartment.images} onClick={toggleShowAllPhotos} />

        {/* Body */}
        <div className="flex flex-col px-6 md:flex-row md:px-0">
          {/* Left Body */}
          <div className="w-full pr-0 md:w-2/3 md:pr-12">
            <SubHeader
              name={apartment.name}
              subHeader={apartment.short_description}
              priceDesc={priceDesc}
              rating="4.13"
              reviews="16 reviews"
            />
            <VibeTags vibeTags={apartment.vibeTags} />

            <PricingAndFloorPlans
              floorPlans={apartment.floorPlans}
              id={apartment.id}
            />
            <SpecialsComponent specials={apartment.specials} />

            <Description
              description={apartment.description}
              toggleShowAllPhotos={toggleShowAllPhotos}
            />

            <FeaturedPhotos
              photos={apartment.featured_images}
              handleClick={toggleShowAllPhotos}
            />

            <AmenitiesComponent
              amenities={apartment.amenities}
              appliances={apartment.appliances}
            />

            <VirtualTour virtualTour={apartment.virtual_tour} />

            <UtilitiesComponent utilities={apartment.utilities} />

            <FeesAndPolicies
              fees={apartment.fees}
              pets={apartment.pets}
              parking={apartment.parking}
            />
          </div>
          {/* Right Body / Sticky Footer */}
          <div className="hidden md:block w-full md:w-1/3 rounded-lg">
            <h1 className="text-lg font-semibold py-4 pt-6 text-end">
              92 Viewed 🔥
            </h1>
            <aside className="self-start sticky top-24 mt-8 col-span-1">
              <ApplyNowForm apartment={apartment} />
              {/* <LookingForRoommates id={apartment.id} userId={user?.uid} /> */}
            </aside>
          </div>
          <div className="fixed bottom-0 left-0 w-full bg-white text-black p-4 md:hidden border-t border-gray-300">
            <div className="flex w-full justify-between">
              <div>
                <div className="text-sm font-semibold">
                  Looking for roommates?
                </div>
                <button className="text-xs font-medium underline">
                  Explore profiles
                </button>
              </div>
              <button
                className="bg-logo_blue text-white text-sm font-semibold py-2 px-8 rounded-md"
                onClick={handleApplyNowClick}
              >
                Apply Now
              </button>
            </div>
          </div>
        </div>
        <Divider />
        <Reviews
          id={apartment.id}
          reviewDesc={apartment.reviewDesc}
          reviewTags={apartment.reviewTags}
        />
        <Divider />
        <div>
          <h2 className="text-xl font-semibold mb-2">Location</h2>
          <h2 className="text-sm font-medium mb-4">{apartment.address}</h2>
          {/* <LocationCard
            latitude={apartment.latitude}
            longitude={apartment.longitude}
          /> */}
        </div>
        <Divider />
      </div>
      <PhotoModal
        showModal={showAllPhotos}
        toggleModal={toggleShowAllPhotos}
        images={apartment.images}
      />
      <Footer />
      {showAlert && (
        <div className="fixed inset-0 flex items-center justify-center">
          <div class="fixed bottom-24 md:bottom-12 md:left-12 p-3 bg-white text-black text-md rounded-lg shadow-xl border transition-opacity duration-500">
            <div className="flex items-center">
              <img
                src={apartment.images[0]}
                className="h-12 w-12 rounded-lg object-cover"
              ></img>
              <div className="ml-3">
                {!isFavorited ? (
                  <div> Removed from saved</div>
                ) : (
                  <div> Added to saved!</div>
                )}

                <div className="text-sm font-semibold underline">View</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={handleCloseModal}
        shareUrl={shareUrl}
        imageLink={apartment.images[0]}
        title={apartment.name}
        shortDescription={priceDesc}
      />
    </div>
  );
}

export const Divider = () => {
  return <hr className="my-8" />;
};

const LookingForRoommates = ({ id, userId }) => {
  const navigate = useNavigate();

  const navigateToProfiles = () => {
    if (userId) {
      logEvent(analytics, "explore_roommates_click", {
        apartmentId: id,
        userId: userId,
        timestamp: Date.now(),
      });
    }
    navigate(`/interestedusers/${id}`);
  };

  return (
    <div className="">
      <div className="py-6 px-4 border text-center rounded-md">
        <h2 className="text-lg font-semibold">Looking for roommates?</h2>
        <h2 className="text-sm">
          View 10+ other students looking for roommates
        </h2>
        <div className="flex items-center justify-center mt-3">
          <button
            className="flex items-center space-x-1"
            onClick={navigateToProfiles}
          >
            <img src={pfpImages} alt="chevron-left" className="h-5" />
            <h2 className="text-sm underline text-gray-500 font-semibold">
              Explore profiles
            </h2>
          </button>
        </div>
      </div>
      {/* <TourBookingForm /> */}
    </div>
  );
};

const SubHeader = ({ name, subHeader, priceDesc, rating, reviews }) => {
  return (
    <>
      {/* SubHeader for small screens */}
      <div className="container pt-4 md:hidden">
        <h1 className="text-2xl font-semibold mb-4">{name}</h1>
        <h1 className="text-md font-medium">{subHeader}</h1>
        <div className="text-sm font-regular py-1">
          <span>{priceDesc}</span>
        </div>
        {/* <div className="text-sm flex items-center font-semibold">
          <span className="mr-1">★</span>
          <span>{rating}</span>
          <span className="mx-1">·</span>
          <span className="underline">{reviews}</span>
        </div> */}
      </div>

      {/* SubHeader for larger screens */}
      <div className="container pt-6 hidden md:block">
        <h1 className="text-xl font-semibold">{subHeader}</h1>
        <div className="text-md font-regular py-1">
          <span>{priceDesc}</span>
        </div>
        {/* <div className="text-md flex items-center font-semibold">
          <span className="mr-1">★</span>
          <span>{rating}</span>
          <span className="mx-1">·</span>
          <span className="underline">{reviews}</span>
        </div> */}
      </div>
    </>
  );
};

const VibeTags = ({ vibeTags }) => {
  const [isVibeModalOpen, setIsVibeModalOpen] = useState(false);

  const handleVibeShowMore = () => {
    setIsVibeModalOpen(true);
  };

  const handleVibeModalClose = () => {
    setIsVibeModalOpen(false);
  };

  if (vibeTags === null) {
    return <></>;
  }

  return (
    <div>
      {vibeTags && (
        <div>
          <Divider />
          <div className="">
            <h2 className="text-xl font-semibold mr-2">Vibe</h2>
            <div className="flex flex-wrap gap-2 my-4 line-clamp-2">
              {vibeTags.slice(0, 8).map((tag, index) => (
                <div
                  key={index}
                  className="bg-gray-100 text-gray-800 px-2 py-1 rounded-lg text-md font-medium"
                >
                  {tag}
                </div>
              ))}
            </div>
            <button
              onClick={handleVibeShowMore}
              className="font-medium underline"
            >
              {"Show More >"}
            </button>
          </div>
          {isVibeModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">Vibe</h2>
                  <button
                    onClick={handleVibeModalClose}
                    className="text-gray-800 text-xl font-semibold"
                  >
                    &times;
                  </button>
                </div>
                <div className="flex flex-wrap gap-2 mb-8">
                  {vibeTags.map((tag, index) => (
                    <div
                      key={index}
                      className="bg-gray-100 text-gray-800 px-2 py-1 rounded-lg text-md font-medium"
                    >
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const PricingAndFloorPlans = ({ id, floorPlans }) => {
  const bedroomTabs = [...new Set(floorPlans.map((plan) => plan.beds))].map(
    (bed) => (bed === 0 ? "Studio" : `${bed} Bedroom`)
  );

  const tabs = ["For You", ...bedroomTabs, "All"];
  const [activeTab, setActiveTab] = useState("For You");

  const parseRent = (rent) => {
    if (typeof rent === "number") {
      return `$${rent} /mo`;
    }
    if (typeof rent === "string" && /^\d+$/.test(rent)) {
      return `$${rent} /mo`;
    } else if (typeof rent === "string" && /^\d+-\d+$/.test(rent)) {
      return `$${rent.replace("-", "-$")} /mo`;
    } else {
      return rent;
    }
  };

  const isAvailable = (availability) => {
    const unavailableKeywords = ["sold", "waitlist", "unavailable"];
    return !unavailableKeywords.some((keyword) =>
      availability.toLowerCase().includes(keyword)
    );
  };

  const getForYouPlans = (plans) => {
    const forYouPlans = {};
    plans.forEach((plan) => {
      if (isAvailable(plan.availability)) {
        const key = `${plan.beds}x${plan.bath}`;
        if (
          !forYouPlans[key] ||
          parseInt(plan.rent) < parseInt(forYouPlans[key].rent)
        ) {
          forYouPlans[key] = plan;
        }
      }
    });
    return Object.values(forYouPlans);
  };

  const forYouPlans = getForYouPlans(floorPlans);

  const filteredPlans = floorPlans.filter((plan) => {
    if (activeTab === "For You") {
      return forYouPlans.includes(plan);
    }
    if (activeTab === "All") {
      return true;
    }
    const tabLabel = plan.beds === 0 ? "Studio" : `${plan.beds} Bedroom`;
    return tabLabel === activeTab;
  });

  const sortedPlans = filteredPlans.sort((a, b) => {
    const aAvailable = isAvailable(a.availability);
    const bAvailable = isAvailable(b.availability);

    if (aAvailable && !bAvailable) return -1;
    if (!aAvailable && bAvailable) return 1;

    const parseRent = (rent) => {
      if (typeof rent === "string") {
        return parseInt(rent.split("-")[0]);
      } else if (typeof rent === "number") {
        return rent;
      } else {
        throw new Error("Invalid rent type");
      }
    };

    return parseRent(a.rent) - parseRent(b.rent);
  });

  return (
    <div className="container">
      <Divider />

      <h2 className="text-xl font-semibold mb-2 md:mb-3">
        Pricing and Floor Plans
      </h2>
      <div className="">
        <nav className="flex flex-wrap justify-start items-start">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`border-b-2 md:py-2 py-1 font-medium text-md mr-8 ${
                activeTab === tab
                  ? "border-logo_blue text-logo_blue"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </nav>
      </div>
      {sortedPlans.length === 0 ? (
        <div className="text-center text-gray-700 mt-4">
          No floor plans available
        </div>
      ) : (
        sortedPlans.map((plan) => (
          <div key={plan.name} className="my-2">
            <div className="border border-gray-200 rounded-md p-4">
              <div className="flex items-center">
                <div className="flex justify-center items-center w-1/2">
                  <img
                    src={plan.img || floorplanPlaceholderImg}
                    alt={plan.name}
                    className="h-36 object-cover rounded-lg"
                    onError={(e) => (e.target.src = floorplanPlaceholderImg)}
                  />
                </div>
                <div className="flex flex-col ml-4">
                  <h2 className="text-xl font-medium py-2">{plan.name}</h2>
                  <h2 className="text-lg font-medium">
                    {parseRent(plan.rent)}
                  </h2>
                  <div className="text-gray-700 text-sm py-1">
                    <span className="font-medium">{plan.beds} Bed</span>
                    <span className="mx-1">|</span>
                    <span className="font-medium">{plan.bath} Bath</span>
                    {plan.sf && (
                      <span>
                        <span className="mx-1">|</span>
                        <span className="font-medium">{plan.sf} Sq. Ft.</span>
                      </span>
                    )}
                  </div>
                  <div className="text-gray-700 text-sm">
                    <span>{plan.availability}</span>
                  </div>
                </div>
              </div>
              {plan.special && (
                <div className="flex items-center">
                  <div className="flex justify-center items-center w-1/2"></div>
                  <div className="flex flex-col ml-4">
                    <span class="font-medium">🌟 {plan.special}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

const Description = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  if (!description) {
    return null;
  }

  return (
    <div className="container">
      <Divider />
      <h2 className="text-xl font-semibold mb-2 md:mb-3">Description</h2>
      <div className="mb-4">
        <p className={`overflow-hidden ${isExpanded ? "" : "line-clamp-3"}`}>
          {description}
        </p>
      </div>
      <button onClick={toggleDescription} className="font-medium underline">
        {isExpanded ? "Show Less" : "Show More >"}
      </button>
    </div>
  );
};

const CustomPrevArrow = ({ onClick }) => (
  <button
    className="p-1 bg-white border border-gray-300 rounded-full"
    onClick={onClick}
  >
    <ChevronLeft style={{ height: 20, width: 20 }} />
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button
    className="p-1 bg-white border border-gray-300 rounded-full rotate-180"
    onClick={onClick}
  >
    <ChevronLeft style={{ height: 20, width: 20 }} />
  </button>
);

const FeaturedPhotos = ({ photos, handleClick }) => {
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const slidesToShow = 2;
  const totalGroups =
    photos == null ? 0 : Math.ceil(photos.length / slidesToShow);

  useEffect(() => {
    if (photos === null || photos === undefined) {
      return;
    }
    const currentGroup = Math.ceil((currentIndex + 1) / slidesToShow);
    document.getElementById(
      "slider-status"
    ).innerText = `${currentGroup} / ${totalGroups}`;
  }, [currentIndex, slidesToShow, totalGroups]);

  if (photos === null || photos === undefined) {
    return null;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: slidesToShow,
    arrows: false,
    dots: false,
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  };

  return (
    <div className="container">
      <Divider />
      <div className="flex justify-between items-center mb-4 relative">
        <h2 className="text-xl font-semibold">Featured Photos</h2>
        <div className="flex items-center space-x-4 absolute right-0 top-0">
          <div id="slider-status" className="text-sm font-light">
            1 / {totalGroups}
          </div>
          <CustomPrevArrow onClick={() => sliderRef.current.slickPrev()} />
          <CustomNextArrow onClick={() => sliderRef.current.slickNext()} />
        </div>
      </div>
      <Slider ref={sliderRef} {...settings} className="space-x-4 pt-2">
        {photos.map((photo, index) => (
          <div
            key={index}
            onClick={handleClick}
            className="w-full flex-shrink-0 px-2"
          >
            <div className={`w-full h-36 md:h-56`}>
              <img
                src={photo.link}
                alt={photo.name}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div className="mt-4 text-left">
              <div className="font-medium">{photo.name}</div>
            </div>
          </div>
        ))}
      </Slider>
      <button onClick={handleClick} className="pt-4 font-medium underline ">
        {"View All Photos >"}
      </button>
    </div>
  );
};

const AmenitiesComponent = ({ amenities = [], appliances = [] }) => {
  const combinedItems = [...amenities, ...appliances].slice(0, 10);
  const totalItems = amenities.length + appliances.length;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  if (totalItems === 0) {
    return null;
  }

  return (
    <div className="container">
      <Divider />
      <div className="mb-4">
        <h2 className="text-xl font-semibold">What this place offers</h2>
      </div>

      <ul className="grid grid-cols-2 gap-4 list-disc pl-5">
        {combinedItems.map((item, index) => (
          <li key={index} className="text-base">
            {item}
          </li>
        ))}
      </ul>

      <button
        className="mt-8 px-4 py-2 font-medium border border-black rounded-md hover:bg-gray-100"
        onClick={openModal}
      >
        Show all {totalItems} amenities
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg w-full max-w-3xl h-full md:h-5/6 overflow-auto">
            <div className="sticky top-0 bg-white flex justify-between items-center p-4">
              <button
                onClick={closeModal}
                className="text-gray-500 hover:text-gray-700 text-2xl"
              >
                &times;
              </button>
            </div>
            <div className="px-6">
              <h2 className="text-2xl font-medium">What this place offers</h2>
              <h3 className="text-xl font-medium py-8">Amenities</h3>
              <ul className="list-disc pl-5">
                {amenities.map((item, index) => (
                  <li key={index} className="text-base">
                    {item}
                    <Divider />
                  </li>
                ))}
              </ul>

              <h3 className="text-xl font-medium py-8">Appliances</h3>
              <ul className="list-disc pl-5">
                {appliances.map((item, index) => (
                  <li key={index} className="text-base">
                    {item}
                    <Divider />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const UtilitiesComponent = ({ utilities }) => {
  if (!utilities) {
    return (
      <div className="container">
        <Divider />
        <div className="mb-4">
          <h2 className="text-xl font-semibold">Utilities</h2>
        </div>

        <div className="px-0">
          <div className="mb-6">
            <h3 className="text-base">Contact office for utilities info</h3>
          </div>
        </div>
      </div>
    );
  }

  const { included, fee } = utilities;

  return (
    <div className="container">
      <Divider />
      <div className="mb-4">
        <h2 className="text-xl font-semibold ">Utilities</h2>
      </div>

      <div className="px-0">
        {/* Included */}
        {included && included.length > 0 && (
          <div className="mb-6">
            <h3 className="text-base font-medium">Included</h3>
            <div className="my-2" />
            <ul className="flex flex-wrap gap-x-8 gap-y-2 list-disc pl-5">
              {included.map((item, index) => (
                <li key={index} className="text-base mr-4">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
        {/* with fee */}
        {fee && fee.length > 0 && (
          <div>
            <h3 className="text-base font-medium">With Fee</h3>
            <div className="my-2" />
            <ul className="flex flex-wrap gap-x-8 gap-y-2 list-disc pl-5">
              {fee.map((item, index) => (
                <li key={index} className="text-base mr-4">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const SpecialsComponent = ({ specials }) => {
  const [isModalOpen, setIsGroupModalOpen] = useState(false);
  const [isGroupContactModalOpen, setIsGroupContactModalOpen] = useState(false);

  const openModal = () => setIsGroupModalOpen(true);
  const closeModal = () => setIsGroupModalOpen(false);

  if (!specials) return <div />;

  const { general, groupDealRates, groupDealValid } = specials;

  return (
    <div className="container">
      <Divider />
      <div className="mb-4">
        <h2 className="text-xl font-semibold ">New Specials</h2>
      </div>

      <div className="px-0">
        {/* general */}
        {general && general.length > 0 && (
          <div className="mb-6">
            <h3 className="text-base font-medium">{general}</h3>
            <div className="my-2" />
          </div>
        )}
        {/* group deal */}

        {groupDealValid && groupDealRates && groupDealRates.length > 0 && (
          <div>
            <h3 className="text-base font-medium">
              Sign with a group and save now!
            </h3>
            <div className="my-2" />
            <ul className="flex flex-wrap gap-x-8 gap-y-2 list-disc pl-5">
              {groupDealRates.map((deal, index) => (
                <li key={index} className="text-base mr-4">
                  <div>{deal.name}</div>
                  <div>{deal.rate}</div>
                </li>
              ))}
            </ul>
            <button
              className="bg-gradient-blue-r text-white mt-8 px-4 py-2 font-medium rounded-md"
              onClick={openModal}
            >
              Learn more about group deals
            </button>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg w-full max-w-4xl max-h-screen overflow-auto">
            <div className="sticky top-0 bg-white flex justify-between items-center p-4">
              <h2 className="text-2xl font-medium">Group Deals</h2>
              <button
                onClick={closeModal}
                className="text-gray-500 hover:text-gray-700 text-2xl"
              >
                &times;
              </button>
            </div>
            {isGroupContactModalOpen && <div></div>}
            {!isGroupContactModalOpen && (
              <div className="px-4 pb-8">
                <p className="mb-4">
                  Save money on rent by signing with a group of friends or other
                  people. The bigger the group, the more you save! You can
                  either create a group deal or join an existing one.
                </p>
                <h3 className="text-xl font-semibold mb-2">How It Works</h3>
                <p className="mb-2">
                  {" "}
                  <span className="text-blue-500 underline" onClick={() => {}}>
                    Contact
                  </span>{" "}
                  the office to create a group deal.
                </p>
                <p className="mb-2">Generally, this is the process:</p>
                <ul className="list-disc pl-5 mb-4">
                  <li>
                    Contact the office via email to confirm the group deal
                    special is still available.
                  </li>
                  <li>
                    You will be assigned as the "Group Leader" and you will be
                    the point of contact for communication for the members of
                    the group and group deal.
                  </li>
                  <li>
                    The office will send you an Excel sheet to fill out with
                    your group members' information (name, email, floorplan,
                    etc.).
                  </li>
                  <li>
                    All members should input their information in the sheet
                    within a couple of days.
                  </li>
                  <li>
                    The group leader will then contact the office to confirm the
                    sheet is complete.
                  </li>
                  <li>
                    The office will confirm the rate and give you 48 hours for
                    all members to submit their applications and sign their
                    leases.
                  </li>
                  <li>
                    The office will highlight who has signed their lease and who
                    has not.
                  </li>
                  <li>
                    IMPORTANT: The group deal is only valid if ALL MEMBERS SIGN
                    THEIR LEASES.
                  </li>
                </ul>
                <p className="mb-2">
                  Ensure all communications and specials are documented in
                  writing, usually via email.
                </p>
                <p className="mb-4">
                  Once all members have signed their leases and the office
                  confirms this, you will receive your special. This may be in
                  the form of a gift card, a direct rent deduction, or a
                  pre-paid concession card that can be applied towards rent.
                </p>
                <p className="font-medium">
                  Congrats and enjoy your new home :)
                </p>
                <button
                  className="bg-gradient-blue-r text-white mt-4 px-4 py-2 font-medium border border-black rounded-md hover:bg-gray-100"
                  onClick={() => {}}
                >
                  Contact Now
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const FeesAndPolicies = ({ fees, pets, parking }) => {
  const [activeTab, setActiveTab] = useState("fees");

  return (
    <div className="">
      <Divider />
      <h1 className="text-xl font-semibold mb-2">Fees and Policies</h1>
      <div className="border-b border-gray-300">
        <nav className="flex space-x-8">
          <button
            className={`whitespace-nowrap border-b-2 font-medium text-md ${
              activeTab === "fees"
                ? "border-logo_blue text-logo_blue"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
            onClick={() => setActiveTab("fees")}
          >
            Fees
          </button>
          <button
            className={`whitespace-nowrap border-b-2 font-medium text-md ${
              activeTab === "pets"
                ? "border-logo_blue text-logo_blue"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
            onClick={() => setActiveTab("pets")}
          >
            Pets
          </button>
          <button
            className={`whitespace-nowrap py-2 border-b-2 font-medium text-md ${
              activeTab === "parking"
                ? "border-logo_blue text-logo_blue"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
            onClick={() => setActiveTab("parking")}
          >
            Parking
          </button>
        </nav>
      </div>

      <div className="mt-4">
        {activeTab === "fees" && (
          <div>
            {(!fees?.monthly || fees.monthly.length === 0) &&
            (!fees?.single || fees.single.length === 0) ? (
              <p>No fees listed.</p>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {fees?.monthly && fees.monthly.length > 0 && (
                  <div className="col-span-1">
                    <h2 className="text-md font-medium mb-2">
                      Monthly Utilities & Services
                    </h2>
                    <div className="border rounded-md p-4">
                      {fees.monthly.map((item, index) => (
                        <div key={index} className="flex justify-between">
                          <span>{item.name}</span>
                          <span>{item.price}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {fees?.single && fees.single.length > 0 && (
                  <div className="col-span-1">
                    <h2 className="text-md font-medium mb-2">
                      One-Time Move-In Fees
                    </h2>
                    <div className="border rounded-md p-4">
                      {fees.single.map((item, index) => (
                        <div key={index} className="flex justify-between mb-2">
                          <span>{item.name}</span>
                          <span>{item.price}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {activeTab === "pets" && (
          <div>
            <div>
              <h2 className="text-md font-medium mb-2">Pets Policy</h2>
              <p>{pets?.desc || "No pets policy listed."}</p>
            </div>
          </div>
        )}
        {activeTab === "parking" && (
          <div>
            <h2 className="text-md font-medium mb-2">Parking Policy</h2>
            <p>{parking?.desc || "No parking policy listed."}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const VirtualTour = ({ virtualTour }) => {
  if (!virtualTour) {
    return null;
  }

  const renderSingleTour = (link) => (
    <div className="bg-gray-200 rounded-md mb-8 overflow-hidden">
      <iframe
        src={link}
        scrolling="yes"
        className="iframe-holder"
        title="Virtual Tour"
        style={{ width: "100%", height: "500px" }}
        allowFullScreen
      ></iframe>
    </div>
  );

  const renderMultipleTours = (links) => (
    <div className="flex overflow-x-scroll space-x-4 pb-4">
      {links.map((link, index) => (
        <div
          key={index}
          className="bg-gray-200 rounded-md overflow-hidden min-w-[300px]"
        >
          <iframe
            src={link}
            scrolling="yes"
            className="iframe-holder"
            title={`Virtual Tour ${index + 1}`}
            style={{ width: "100%", height: "300px" }}
            allowFullScreen
          ></iframe>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <Divider />
      <div className="pb-4">
        <div className="flex items-end">
          <h1 className="text-xl font-semibold">Virtual Tour</h1>
          <p className="text-xs font-light pb-1 ml-1">
            *full-screen for best experience
          </p>
        </div>
      </div>
      {Array.isArray(virtualTour)
        ? renderMultipleTours(virtualTour)
        : renderSingleTour(virtualTour)}
    </div>
  );
};

export default ApartmentDetail;
