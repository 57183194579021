import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getAnalytics, logEvent } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "adobe-64bfc.firebaseapp.com",
  projectId: "adobe-64bfc",
  storageBucket: "adobe-64bfc.appspot.com",
  messagingSenderId: "866017814136",
  appId: "1:866017814136:web:dad60c154f0fe1c8d4af8d",
  measurementId: "G-RGG3QYTWQS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { auth, db, storage, analytics, logEvent };
