import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { EmailIcon, EmailShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { IoIosMore } from "react-icons/io";

const ShareModal = ({ isOpen, onClose, shareUrl, imageLink, title, shortDescription }) => {
  const [alertVisible, setAlertVisible] = useState(false);

  const handleCopy = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 2000);
  };

  if (!isOpen) {
    return null;
  }

  const handleNativeShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: title,
          text: "Check out this apartment",
          url: shareUrl,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      console.log("Web Share API is not supported in your browser.");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg relative w-full max-w-2xl">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-600">
          &#10005;
        </button>
        <h2 className="text-2xl font-medium">Share this place</h2>
        <div className="flex items-center py-4">
          <img src={imageLink} alt="Place" className="w-24 h-24 object-cover rounded-lg mr-4" />
          <div>
            <h3 className="text-md">{title}</h3>
            <p className="text-sm text-gray-600">{shortDescription}</p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <CopyToClipboard text={shareUrl} onCopy={handleCopy}>
            <button className="flex items-start justify-start p-4 border rounded-lg shadow-sm w-full">
              <img src="https://img.icons8.com/material-rounded/24/000000/copy.png" alt="Copy Link" />
              <span className="ml-2">Copy Link</span>
            </button>
          </CopyToClipboard>
          <EmailShareButton url={shareUrl} subject={title} openWindow={false}>
            <button className="flex items-start justify-start p-4 border rounded-lg shadow-sm w-full">
              <EmailIcon size={24} round />
              <span className="ml-2">Email</span>
            </button>
          </EmailShareButton>
          <button
            onClick={() => window.open(`sms:?&body=${shareUrl}`, '_self')}
            className="flex items-start justify-start p-4 border rounded-lg shadow-sm w-full"
          >
            <img src="https://img.icons8.com/material-rounded/24/000000/speech-bubble.png" alt="Messages" />
            <span className="ml-2">Messages</span>
          </button>
          <WhatsappShareButton url={shareUrl} title={title} openWindow={false}>
            <button className="flex items-start justify-start p-4 border rounded-lg shadow-sm w-full">
              <WhatsappIcon size={24} round />
              <span className="ml-2">WhatsApp</span>
            </button>
          </WhatsappShareButton>
          <button
            onClick={handleNativeShare}
            className="flex items-start justify-start p-4 border rounded-lg shadow-sm w-full"
          >
           <div className="flex items-center justify-start space-x-2 w-full">
  <IoIosMore className="w-8 h-8" />
  <span>More Options</span>
</div>

          </button>
          {/* <FacebookShareButton url={shareUrl} quote={title}>
            <button className="flex items-start justify-start p-4 border rounded-lg shadow-sm w-full">
              <FacebookIcon size={24} round />
              <span className="ml-2">Facebook</span>
            </button>
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl} title={title}>
            <button className="flex items-start justify-start p-4 border rounded-lg shadow-sm w-full">
              <TwitterIcon size={24} round />
              <span className="ml-2">Twitter</span>
            </button>
          </TwitterShareButton> */}
        </div>
        {alertVisible && (
          <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 bg-white text-black border px-4 py-2 rounded transition-opacity duration-500">
            Link copied!
          </div>
        )}
      </div>
    </div>
  );
};

export default ShareModal;
