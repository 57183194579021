import React, { useState, useEffect, useMemo } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  InfoWindow,
  useMap,
  Pin,
} from "@vis.gl/react-google-maps";
import { InfoWindowCard } from "../Listings/ListingCard";
import { getLocationCoordinates } from "../../backend/utils";
// eslint-disable-next-line

const Markers = ({
  apartments,
  filters,
  selectedApartment,
  setSelectedApartment,
}) => {
  const map = useMap();
  const selLoc = filters.selectedLocation;
  const hoverAptID = filters.hoverAptID;

  const handleMarkerClick = (apartment) => {
    setSelectedApartment(apartment);
  };

  const handleCloseInfoWindow = () => {
    setSelectedApartment(null);
    // map.setZoom(15);
    // map.panTo(getLocationCoordinates(selLoc));
  };

  useEffect(() => {
    if (!map) return;
  }, [map]);

  return (
    <>
      <AdvancedMarker position={getLocationCoordinates(selLoc)}>
        <Pin
          background="#FF5733" // Vibrant red for the user location
          scale={0.8} // Slightly larger to emphasize importance
          glyphColor="#FFFFFF" // White for visibility
          borderColor="#CC4400" // A darker shade of the base color for depth
        ></Pin>
      </AdvancedMarker>

      {apartments.map((apartment) => {
  // Parse latitude and longitude as floats
  const latitude = parseFloat(apartment.latitude);
  const longitude = parseFloat(apartment.longitude);

  // Check if the latitude and longitude are valid numbers
  const isValidLatLng = !isNaN(latitude) && !isNaN(longitude);

  if (!isValidLatLng) {
    console.log(`Invalid latitude or longitude for apartment ID: ${apartment.id}`);
    return null; // Skip rendering this apartment if invalid
  }

  return (
    <AdvancedMarker
      key={apartment.id}
      position={{ lat: latitude, lng: longitude }}
      onClick={() => handleMarkerClick(apartment)}
      zIndex={hoverAptID === apartment.id ? 2 : 1}
    >
      <Pin
        background={hoverAptID === apartment.id ? "#0A2463" : "#7A9ECF"} // Green for hover, blue for default
        scale={hoverAptID === apartment.id ? 1.2 : 1}
        glyphColor="#FFFFFF" // White for both hover and default for consistency
        borderColor={hoverAptID === apartment.id ? "#0A2463" : "#0A2463"} // Darker green for hover, darker blue for default
      ></Pin>

      {/* Uncomment this if you want an alternative color scheme
      <Pin
        background={hoverAptID === apartment.id ? "#FFA726" : "#64B5F6"} // Orange for hover, Light Blue for default
        scale={hoverAptID === apartment.id ? 1.2 : 1}
        glyphColor="#FFFFFF" // White for both hover and default for clarity
        borderColor={hoverAptID === apartment.id ? "#FB8C00" : "#2196F3"} // Darker Orange for hover border, Stronger Blue for default
      ></Pin>
      */}
    </AdvancedMarker>
  );
})}

      {selectedApartment && (
        <InfoWindow
          position={{
            lat: selectedApartment.latitude,
            lng: selectedApartment.longitude,
          }}
          onCloseClick={() => handleCloseInfoWindow()}
          // pixelOffset={{width: 300, height: 0}}
        >
          <div style={{ width: "300px" }}>
            <InfoWindowCard apartment={selectedApartment} filters={filters} />
          </div>
        </InfoWindow>
      )}
    </>
  );
};

function DisplayMap({ apartments, filters }) {
  const [selectedApartment, setSelectedApartment] = useState(null);
  const selLoc = filters.selectedLocation;

  const handleCloseInfoWindow = () => {
    if (selectedApartment !== null) {
      setSelectedApartment(null);
    }
  };

  const defaultCenter = useMemo(() => getLocationCoordinates(selLoc), [selLoc]);

  useEffect(() => {
    console.log("Map Rendered");
  }, []);

  return (
    <APIProvider apiKey="AIzaSyCU2HcNBFcNhluwAQ9YlTscpNqU0RvD-8c">
      <Map
        defaultCenter={defaultCenter}
        defaultZoom={15}
        mapId="5931d562ae32bd1c"
        onDrag={handleCloseInfoWindow}
      >
        <Markers
          apartments={apartments}
          filters={filters}
          selectedApartment={selectedApartment}
          setSelectedApartment={setSelectedApartment}
        />
      </Map>
    </APIProvider>
  );
}

export default DisplayMap;
