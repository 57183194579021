import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../firebase/index";
import { doc, getDoc } from "firebase/firestore";
import UserCards from "./Roommates/UserCards";
import MainHeader from "../components/Headers/MainHeader";
import AddToRoommateListPopup from "../components/Roommates/AddToRoommateListPopup";
import {
  isUserOnAptRoommateList,
  addUserToRoommateList,
} from "../backend/dbAPI";
import { fetchSpecificApartmentName } from "../backend/dynamoAPI";
import ProfileCarousel, { ProfileCard } from "./ExampleRoommateProfiles";
import { CgOptions } from "react-icons/cg";

function InterestedUsers() {
  const [my_user] = useAuthState(auth);
  const { apartmentId: baseApartmentId = "general", secondParam } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [showAddToListPopup, setShowAddToListPopup] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false); // New state for filters popup
  const [userProfile, setUserProfile] = useState(null);
  const [apartmentName, setApartmentName] = useState(null);
  const navigate = useNavigate();
  const [studentFilters, setStudentFilters] = useState({
    gender: "all", // Default to "all"
  });

  // Combine apartmentId and secondParam if secondParam exists
  const apartmentId = secondParam
    ? `${baseApartmentId}_${secondParam}`
    : baseApartmentId;
  const trueApartmentId = secondParam
    ? `${baseApartmentId}/${secondParam}`
    : baseApartmentId;

  useEffect(() => {
    // Scroll to top of the page on component mount
    window.scrollTo(0, 0);

    const fetchUserProfile = async () => {
      if (my_user) {
        const userDocRef = doc(db, "users", my_user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          if (data) {
            setUserProfile(data);
            // Set gender filter based on user's gender or default to "all"
            setStudentFilters((prevFilters) => ({
              ...prevFilters,
              gender: data.gender ? data.gender : "all",
            }));

            // Check if the user is on the apartment roommate list
            if (data.showRoommateProfile != null) {
              const isOnList = await isUserOnAptRoommateList(
                my_user.uid,
                apartmentId
              );
              if (!isOnList) {
                // setShowAddToListPopup(true); // Show the popup if the user is not on the list
                handleAddToListConfirm();
              }
            } else {
              setShowModal(true);
            }
            if (data.schoolEmail === null) {
              setShowStudentModal(true);
            }
          } else {
            setShowModal(true);
          }
        } else {
          setShowModal(true);
        }
      } else {
        setShowModal(true);
      }
    };

    const fetchApartmentName = async () => {
      if (baseApartmentId !== "general") {
        try {
          const apt = await fetchSpecificApartmentName(trueApartmentId);
          setApartmentName(apt?.name || "Unknown Apartment"); // Fallback to a default value
        } catch (error) {
          console.error("Error fetching apartment name:", error);
        }
      }
    };

    fetchUserProfile();
    fetchApartmentName(); // Fetch the apartment name if baseApartmentId is not "general"
  }, [apartmentId, my_user, baseApartmentId, trueApartmentId]);

  // Function to handle opening and closing the filter modal
  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  // Function to handle gender filter changes
  const handleGenderChange = (event) => {
    setStudentFilters((prevFilters) => ({
      ...prevFilters,
      gender: event.target.value,
    }));
  };

  const CreateRoommateProfileModal = () => {
    const profile = {
      name: "Adarsh Dayalan",
      majorDesc: "Freshman in Computer Science",
      desc: "Interested in a place with a good gym and looking at 3-5 bed apartments. I'm an easygoing dude looking for a couple of chill roommates. Hit me up if you're interested!",
      profilePicture:
        "https://firebasestorage.googleapis.com/v0/b/adobe-64bfc.appspot.com/o/profile_pictures%2FAdarshInstagram.jpg?alt=media&token=5f67cf8e-1cc0-4b5a-8350-e93d7f9055db",
      instagramHandle: "@adarsh.dayalan",
      interests: ["Fitness", "Basketball", "Cooking", "Music"],
      priceRange: "$800-$1000",
    };

    const handleCreateProfileClick = () => {
      if (my_user) {
        window.location.href = `/create-roommate-profile/${apartmentId}`;
      } else {
        alert(
          "Please sign up and create a account first before creating a roommate profile."
        );
      }
    };
    const handleBackClick = () => {
      navigate("/listings#apartments");
    };

    return (
      <div className="absolute inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center w-full max-w-screen p-4">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-7xl flex flex-col md:flex-row">
          <div className="w-full md:w-3/5 pr-6">
            <h2 className="text-2xl font-semibold">
              Looking for Roommates or Connections at UIUC?
            </h2>
            <h2 className="text-lg font-medium mt-2">
              Create a profile to connect with hundreds of UIUC students looking
              for roommates!
            </h2>

            <div className="mt-4 space-y-2">
              <p className="text-md">
                ✔ Match with students who share your vibe, living habits, and
                budget.
              </p>
              <p className="text-md">
                ✔ View Instagram profiles, bios, and hobbies to find your
                perfect roommate.
              </p>
              <p className="text-md">
                ✔ Filter by price range, living habits, major, and more.
              </p>
              <p className="text-md">
                ✔ Take 3 minutes to ensure a great roommate experience for the
                year!
              </p>
            </div>

            <div className="flex flex-col mt-6">
              <button
                onClick={handleCreateProfileClick}
                className="bg-logo_blue text-white px-4 py-2 rounded"
              >
                Create Roommate Profile
              </button>
              <button
                onClick={handleBackClick}
                className="mt-2 text-gray-700 underline"
              >
                No thanks, take me back to apartments
              </button>
            </div>
          </div>

          {/* Profile Card on the Right Side */}
          <div className="w-full md:w-2/5 mt-6 md:mt-0 md:ml-4">
            <h2 className="text-xl font-medium text-gray-700 mb-4">
              Example Student Profile
            </h2>
            <div className="shadow-lg rounded-lg p-4 bg-gray-50">
              <ProfileCard profile={profile} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleAddToListConfirm = async () => {
    try {
      await addUserToRoommateList(my_user.uid, apartmentId);
      setShowAddToListPopup(false);
    } catch (error) {
      console.error("Error adding user to roommate list:", error);
      alert("Failed to add you to the roommate list. Please try again.");
    }
  };

  const handleAddToListCancel = () => {
    setShowAddToListPopup(false);
  };

  // Render the filter modal
  const renderFilterModal = () => {
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-start p-4">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mt-20 overflow-y-auto max-h-[80vh]">
          <h2 className="text-xl font-semibold mb-4">Filter Students</h2>
          <label htmlFor="genderFilter" className="block text-gray-700 mb-2">
            Gender:
          </label>
          <select
            id="genderFilter"
            value={studentFilters.gender}
            onChange={handleGenderChange}
            className="w-full p-2 border rounded-md mb-4"
          >
            <option value="all">All</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setShowFilterModal(false)}
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded"
            >
              Cancel
            </button>
            <button
              onClick={() => setShowFilterModal(false)}
              className="bg-logo_blue text-white px-4 py-2 rounded"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-50">
      {apartmentId !== "general" && <MainHeader />}

      <div className="relative min-h-screen p-4">
        {userProfile ? (
          <div>
            <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between mb-4">
                <h1 className="text-2xl font-semibold">
                  Interested Students {apartmentName && ` - ${apartmentName}`}
                </h1>
                <button
                  onClick={toggleFilterModal}
                  className="flex items-center p-2 h-12 text-black bg-white border border-gray-300 rounded-md"
                >
                  <CgOptions className="h-12 mr-1" />
                  <span className="text-sm font-medium">Filters</span>
                </button>
              </div>
              <UserCards filters={studentFilters} apartmentId={apartmentId} />
            </div>
          </div>
        ) : (
          <div>Displaying example profiles...</div>
        )}
        {showStudentModal && <div></div>}
        {showModal && <CreateRoommateProfileModal />}
        {showAddToListPopup && (
          <AddToRoommateListPopup
            onConfirm={handleAddToListConfirm}
            onCancel={handleAddToListCancel}
          />
        )}
        {showFilterModal && renderFilterModal()} {/* Render the filter modal */}
      </div>
    </div>
  );
}

export default InterestedUsers;
