import {
    arrayUnion,
    arrayRemove,
    doc,
    getDoc,
    setDoc,
    updateDoc,
    query,
    collection,
    getDocs,
    where,
    deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase/index";

const MAX_GROUP_SIZE = 10;

export async function createGroup(groupName, creatorId) {
    const groupRef = doc(collection(db, 'groups'));
    try {
        await setDoc(groupRef, {
            name: groupName,
            members: [creatorId],
        });
        await updateDoc(doc(db, "users", creatorId), { groupID: groupRef.id });
        return groupRef.id
    } catch (error) {
        throw error;
    }
}

export async function inviteToGroup(groupId, userId, fromUser) {
    const groupRef = doc(db, 'groups', groupId);
    const groupDoc = await getDoc(groupRef);

    if (!groupDoc.exists()) {
        throw new Error('Group not found');
    }

    const groupData = groupDoc.data();
    if (groupData.members.length >= MAX_GROUP_SIZE) {
        throw new Error('Group is full');
    }

    const groupRequestRef = doc(collection(db, 'groupRequests'));
    try {
        await setDoc(groupRequestRef, {
            fromUser,
            groupId,
            userId,
            status: 'pending',
        });
    } catch (error) {
        throw error;
    }
}

export async function acceptGroupRequest(requestId) {
    const requestRef = doc(db, 'groupRequests', requestId);
    const requestDoc = await getDoc(requestRef);
    if (!requestDoc.exists()) {
        throw new Error('Group request not found');
    }

    const { groupId, userId } = requestDoc.data();
    const groupRef = doc(db, 'groups', groupId);
    const groupDoc = await getDoc(groupRef);

    if (!groupDoc.exists()) {
        throw new Error('Group not found');
    }

    const groupData = groupDoc.data();
    if (groupData.members.length >= MAX_GROUP_SIZE) {
        throw new Error('Group is full');
    }

    try {
        await updateDoc(groupRef, {
            members: arrayUnion(userId),
        });
        await updateDoc(doc(db, "users", userId), { groupID: groupId });
        await deleteDoc(requestRef);
        console.log('Group request accepted and user added to group.');
    } catch (error) {
        console.error('Error accepting group request:', error);
        throw error;
    }
}

export async function declineGroupRequest(requestId) {
    const requestRef = doc(db, 'groupRequests', requestId);
    const requestDoc = await getDoc(requestRef);

    if (!requestDoc.exists()) {
        throw new Error('Group request not found');
    }

    try {
        await updateDoc(requestRef, { status: 'declined' });
        await deleteDoc(requestRef);
        console.log('Group request declined successfully.');
    } catch (error) {
        console.error('Error declining group request:', error);
        throw error;
    }
}

export async function fetchGroupRequests(userId) {
    if (!userId) {
        throw new Error("User ID must be provided.");
    }

    const groupRequestsQuery = query(
        collection(db, 'groupRequests'),
        where('userId', '==', userId),
        where('status', '==', 'pending')
    );

    try {
        const querySnapshot = await getDocs(groupRequestsQuery);
        const groupRequests = await Promise.all(querySnapshot.docs.map(async (docSnapshot) => {
            const requestData = docSnapshot.data();
            const fromUserDoc = await getDoc(doc(db, 'users', requestData.fromUser));
            const fromUser = fromUserDoc.exists() ? `${fromUserDoc.data().firstName} ${fromUserDoc.data().lastName}` : 'Unknown User';

            return {
                id: docSnapshot.id,
                fromUser: fromUser
            };
        }));
        return groupRequests;
    } catch (error) {
        console.error('Error fetching group requests:', error);
        throw error;
    }
}

export async function fetchGroupMembers(groupId) {
    const groupDoc = await getDoc(doc(db, "groups", groupId));
    if (!groupDoc.exists) {
        throw new Error("Group not found");
    }

    const groupData = groupDoc.data();
    const memberPromises = groupData.members.map(memberId => getDoc(doc(db, "users", memberId)));
    const memberDocs = await Promise.all(memberPromises);

    return memberDocs.map(memberDoc => ({
        id: memberDoc.id,
        ...memberDoc.data(),
    }));
}

export async function fetchGroups(userId) {
    const groupsRef = collection(db, 'groups');
    const q = query(groupsRef, where('members', 'array-contains', userId));
    try {
        const querySnapshot = await getDocs(q);
        const groups = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        return groups;
    } catch (error) {
        console.error('Error fetching groups:', error);
        throw error;
    }
}

export async function fetchUserGroup(userId) {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists() && userDoc.data().groupID) {
        return userDoc.data().groupID;
    }
    return null;
}

export async function leaveGroup(userId) {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
        throw new Error('User not found');
    }

    const groupId = userDoc.data().groupID;
    if (!groupId) {
        throw new Error('User is not in a group');
    }

    const groupRef = doc(db, 'groups', groupId);

    try {
        await updateDoc(groupRef, {
            members: arrayRemove(userId),
        });
        await updateDoc(userRef, { groupID: '' });
        console.log('User left the group successfully.');
    } catch (error) {
        console.error('Error leaving the group:', error);
        throw error;
    }
}
