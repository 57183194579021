import React, { useState } from "react";
import { InterestsList } from "../data/consts/InterestsList";

// pickleball, health care, medicine, law, TV shows,
//Walking, type of music, broaden music
//Food cuisines
//Reality tv, game shows, youtube

//Favorite shows. What are you watching now? Suits, One Piece, etc...
//Clubs

//CIF, GRainger
//I;m living with _, _, looking for _
//other

// Hey! My name is Adi and I'm a senior studying Bio and Psych on the pre-med track. In my free time, I love to sing, play basketball and football with friends, and go climbing at Urbana Boulders.
//How should roommates respond
//Late night fun
//Room Sync
//   ],
// };

const InterestTiles = ({ interests, setInterests, shows, setShows }) => {
  const [selectedInterests, setSelectedInterests] = useState(
    interests ? interests : {}
  );

  const handleShowsChange = (event) => {
    setShows(event.target.value);
  };

  const toggleInterest = (interest) => {
    setSelectedInterests((prevSelectedInterests) => {
      const currentLevel = prevSelectedInterests[interest] || 0;
      const newLevel = (currentLevel + 1) % 4;
      let updatedInterests;

      if (newLevel === 0) {
        const { [interest]: _, ...rest } = prevSelectedInterests;
        updatedInterests = rest;
      } else {
        updatedInterests = { ...prevSelectedInterests, [interest]: newLevel };
      }
      console.log(updatedInterests);
      setInterests(updatedInterests);
      return updatedInterests;
    });
  };

  const getInterestLevelClass = (level) => {
    switch (level) {
      case 1:
        return "bg-blue-400 text-white";
      case 2:
        return "bg-blue-800 text-white";
      case 3:
        return "bg-logo_blue text-white";
      default:
        return "";
    }
  };

  const getInterestLevelText = (level) => {
    switch (level) {
      case 1:
        return "Interested";
      case 2:
        return "Love it";
      case 3:
        return "This is my life";
      default:
        return "";
    }
  };

  return (
    <div>
      <h2 className="text-lg font-medium">Select Your Hobbies/Interests</h2>
      <h2 className="pb-4 text-sm font-medium text-gray-500">
        Click a tile multiple times to show your interest level strength. Click
        1x - Intersted, 2x - Love it, 3x - This is my life.
      </h2>
      <div className="">
        {Object.keys(InterestsList).map((category) => (
          <div key={category} className="mb-16">
            <h3 className="text-xl font-medium mb-8">{category}</h3>
            <div className="grid grid-cols-4 gap-4">
              {InterestsList[category].map((interest) => (
                <div
                  key={interest.name}
                  onClick={() => toggleInterest(interest.name)}
                  className={`flex flex-col items-center p-4 border rounded-lg hover:shadow-md transition-shadow duration-300 cursor-pointer ${getInterestLevelClass(
                    selectedInterests[interest.name]
                  )}`}
                >
                  <div className="text-3xl">{interest.icon}</div>
                  <div className="mt-2 text-center text-sm font-semibold">
                    {interest.name}
                  </div>
                  {selectedInterests[interest.name] && (
                    <div className="mt-1 text-xs font-medium text-center">
                      {getInterestLevelText(selectedInterests[interest.name])}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="">
        <h3 className="text-lg font-medium">
          What shows are you watching now?
        </h3>
        <input
          type="text"
          placeholder="Suits, One Piece, The Office, Too Hot to Handle, etc."
          className="form-input w-full border mt-2 p-2"
          value={shows}
          onChange={handleShowsChange}
        />
      </div>
      <div className="mt-4">
        <h3 className="text-lg font-medium">Selected Interests:</h3>
        <div className="flex flex-wrap mt-2">
          {Object.entries(selectedInterests).map(([interest, level]) => (
            <div
              key={interest}
              className={`px-4 py-2 m-1 rounded-lg ${getInterestLevelClass(
                level
              )}`}
            >
              {interest}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InterestTiles;
